import Link from 'next/link';
import styles from './PostPreviewBox.module.css';
import helperStyles from './Helpers.module.css';
import { Post } from '../types/api';

const Component: React.FC<{ post: Post }> = ({ post }) => (
  <Link href={`/blog/${post.slug}`}>
    <a className={styles.box}>
      <div className={`${styles.coverWrapper} ${helperStyles.anchorNoStyle}`}>
        <picture>
          <source srcSet={post.coverImage?.webp} type="image/webp" />
          <source srcSet={post.coverImage?.url} type="image/png" />
          <source srcSet={post.coverImage?.url} type="image/jpeg" />
          <img
            src={post.coverImage?.url}
            alt="cover"
            className={styles.cover}
          />
        </picture>
      </div>

      <h3
        style={{
          margin: '10px 0',
        }}
        className={styles.title}
      >
        {post.title}
      </h3>

      <p>
        {post.excerpt}
        <br style={{ lineHeight: '180%' }} />
        <Link href={`/blog/${post.slug}`}>
          <a className={styles.readMore}>
            أكمل القرائة...
          </a>
        </Link>
      </p>
    </a>
  </Link>
);

export default Component;
