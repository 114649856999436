import PostPreviewBox from './PostPreviewBox';

import styles from './BlogSection.module.css';
import helperStyles from './Helpers.module.css';
import { Post } from '../types/api';

const Component: React.FC<{ posts: Post[] }> = ({ posts }) => (
  <section className={`${helperStyles.limitWidth} ${helperStyles.sidePadding} ${styles.section}`}>
    <div className={styles.textSection}>
      <h2 style={{
        margin: '10px 0',
      }}
      >
        مقالات
      </h2>

    </div>
    <div className={styles.previewSection}>
      {posts.map((post) => (
        <PostPreviewBox post={post} key={post.slug} />
      ))}
    </div>
  </section>
);

export default Component;
