import styles from './HeroSection.module.css';
import helperStyles from './Helpers.module.css';
import { HeroPage } from '../types/api';
import TrebleClef from '../public/icons/treble-clef.svg';

const Component: React.FC<{ heroPage: HeroPage }> = ({ heroPage }) => (
  <section className={`${helperStyles.limitWidth} ${helperStyles.sidePadding} ${styles.section}`}>
    <div className={styles.pictureSection}>
      <picture>
        <source srcSet={heroPage.heroImage?.webp} type="image/webp" />
        <source srcSet={heroPage.heroImage?.url} type="image/png" />
        <source srcSet={heroPage.heroImage?.url} type="image/jpeg" />
        <img
          src={heroPage.heroImage.url}
          alt="A guitar"
          style={{
            width: '100%',
          }}
        />
      </picture>
    </div>

    <div className={styles.textSection}>
      <div style={{
        display: 'flex',
        alignItems: 'center',
      }}
      >
        <div style={{
          width: '80px',
          display: 'flex',
          alignItems: 'center',
          // transform: 'scaleX(-1)',
          marginInlineEnd: '15px',
        }}
        >
          <TrebleClef />
        </div>

        <div>
          <h1 style={{
            fontSize: '22px',
            fontWeight: 'normal',
            margin: '3px 0',
          }}
          >
            أنا
          </h1>
          <p
            style={{
              fontFamily: 'Vibes, cursive',
              fontWeight: 'bold',
              fontSize: '54px',
              margin: '3px 0',
            }}
          >
            عمر عاشور
          </p>
          <p
            style={{
              margin: '3px 0',
            }}
          >
            ملحن و عازف و مدرس موسيقى
          </p>
        </div>
      </div>
      <br />
      <br />
      <br />
      <h2 style={{
        margin: '10px 0',
      }}
      >
        {heroPage.introSnippetTitle}
      </h2>
      <p style={{
        margin: '6px 0',
      }}
      >
        {heroPage.introSnippet}
      </p>
    </div>
  </section>
);

export default Component;
