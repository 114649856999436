import Head from 'next/head';
import {
  NextPage,
  GetStaticProps,
} from 'next';

import Header from '../components/Header';
import HeroSection from '../components/HeroSection';
import BlogSection from '../components/BlogSection';
import Footer from '../components/Footer';
import { getAllPostsForHome, getHeroPage } from '../lib/graphcms';
import { Post, HeroPage } from '../types/api';
import Separator1 from '../public/separators/line1.svg';
import helperStyles from '../components/Helpers.module.css';
import styles from '../components/index.module.css';

interface PageProps {
  posts: Post[];
  preview: boolean;
  heroPage: HeroPage;
}

const Page: NextPage<PageProps> = ({ posts, preview, heroPage }) => {
  let l;
  return (
    <>
      <Head>
        <meta name="title" content="Ashour Guitar | الرئيسية" />
        <meta name="description" content="إحنا هنا بنحاول نقدملك معلومات ودروس مبنية علي خبرة سنين في تعليم الموسيقي خارج وداخل الشرق الأوسط، وبنحاول نديك خلاصة الخبرة دي بشكل ممتع وبسيط عشان نوقفك علي أرض صلب وتاخد أساس حلو تكمل بيه بأي شكل يريحك بعد كدا. عاشور جبتار معك في رحلتك الموسيقية وهتلاقي هنا رد علي أسألتك واللي يشجعك انك تاخد الأكشن وتكسر الدنيا بدل ما تكسر الجيتار" />
        <title>
          Ashour Guitar | الرئيسية
        </title>
      </Head>
      <Header />
      <main>
        <HeroSection heroPage={heroPage} />
        <div
          style={{
            paddingTop: '40px',
          }}
          className={`${helperStyles.limitWidth} ${styles.separatorWrapper}`}
        >
          <Separator1 />
        </div>

        <BlogSection posts={posts} />
      </main>
      <Footer />
    </>
  );
};

export const getStaticProps: GetStaticProps<{
  posts: Partial<Post>[];
  heroPage: Partial<HeroPage>;
  preview: boolean;
}> = async ({ preview = false }) => {
  const posts = (await getAllPostsForHome(preview)) || [];
  const heroPage = await getHeroPage();
  return {
    props: { posts, preview, heroPage },
  };
};

export default Page;
